import React, { useCallback, useEffect, useState } from "react"
import Table from "react-bootstrap/Table"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import DeleteModal from "./_delete_modal"
import { Link } from "react-router-dom"
import { format } from "date-fns"
import { Admin } from "@/admin"
import type { IAccount, SortOrder, UserData, IDepartment, Pagination } from "@/lib/interface"
import * as api from "@/lib/api"

function App() {
  const [users, setUsers] = useState<UserData[]>([])
  const [usersCognitoData, setUsersCognitoData] = useState<IAccount[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    total: 0,
    totalPages: 0,
    perPage: 60,
  })
  const [departments, setDepartments] = useState<IDepartment[]>([])
  const [admin, setAdmin] = useState<Admin>()
  const [searchText, setSearchText] = useState("")
  const [attempting] = useState(false)
  const [sortType, setSortType] = useState<SortOrder>("ASC")
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [delUserId, setDelUserId] = useState("")
  const [delUserSub, setDelUserSub] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    new Admin(setAdmin)
  }, [])

  useEffect(() => {
    handleFetchUsers(searchText, pagination.currentPage, sortType, true)
  }, [admin])

  const handleIdChange = useCallback((e: any) => setSearchText(e.target.value), [])

  const handleSortTypeChange = () => {
    if (sortType === "ASC") {
      setSortType("DESC")
    } else {
      setSortType("ASC")
    }
  }

  useEffect(() => {
    handleFetchUsers(searchText, pagination.currentPage, sortType, true)
  }, [sortType])

  const handleFetchUsers = async (searchText: string, page: number, sortType: SortOrder, replace = false) => {
    try {
      setLoading(true)
      const searchUsers = await api.getUserDataDb(searchText, page, sortType)
      const cognitoUsers = await checkCognitoUser(searchUsers.data.users)
      const departments = await api.getDepartmentsDb()

      if (replace) {
        setUsersCognitoData(cognitoUsers)
        setUsers(searchUsers.data.users)
      } else {
        setUsersCognitoData([...usersCognitoData, ...cognitoUsers])
        setUsers([...users, ...searchUsers.data.users])
      }

      setPagination(searchUsers.data.pagination)
      setDepartments(departments.data.json)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const checkCognitoUser = async (usersData: UserData[]): Promise<IAccount[]> => {
    const userNameList = usersData.map((user) => user.user_id)
    const promises = userNameList.map(async (userName) => {
      const params = {
        Username: userName,
      }
      return await admin?.getUser(params).catch((err) => {
        console.log(err)
        return null
      })
    })

    const results = await Promise.all(promises)
    const userDataList = results.filter((result) => result !== null)

    return userDataList
  }

  const handleEnable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      if (!username) {
        throw new Error("ユーザは必須です")
      }
      const enableJson = await admin?.enableUser(params)
      console.log(enableJson)
      const updateUsers = usersCognitoData?.map((user: IAccount) => {
        if (user.Username === username) {
          user.Enabled = true
        }
        return user
      })
      setUsersCognitoData(updateUsers)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDesable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      if (!username) {
        throw new Error("ユーザは必須です")
      }
      const disableJson = await admin?.disableUser(params)
      console.log(disableJson)
      const updateUsers = usersCognitoData?.map((user: IAccount) => {
        if (user.Username === username) {
          user.Enabled = false
        }
        return user
      })
      setUsersCognitoData(updateUsers)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async (sub: string, username: string) => {
    setDeleteModalShow(true)
    setDelUserSub(sub)
    setDelUserId(username)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleFetchUsers(searchText, pagination.currentPage, sortType, true)
  }

  return (
    <>
      <DeleteModal
        admin={admin}
        delUserId={delUserId}
        delUserSub={delUserSub}
        users={users}
        setUsers={setUsers}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <div className="row">
        <h1 className="h4 col-12 col-md-5">ユーザ一 一覧（{pagination.total}件）</h1>
        <div className="col-md-4" />
        <div className="col-12 col-md-3">
          <Link to={`/user/create/`} className="btn btn-orange fw-bold float-end w-100">
            新しくユーザーを追加する
          </Link>
        </div>
      </div>
      <hr />

      <Form className="search-area mb-3" onSubmit={handleSubmit}>
        <div className="row mb-4">
          <div className="col-md-4 col-12">
            <Form.Group>
              <Form.Control placeholder="ID" type="text" disabled={attempting} onChange={handleIdChange} />
            </Form.Group>
          </div>
          <div className="col-md-4 col-12 mt-2 mt-md-0">
            <Button type="submit" disabled={attempting} className="search-btn btn-block">
              検索
            </Button>
          </div>
        </div>
      </Form>
      <div className="alert alert-warning">
        無効化されているユーザはログインできません。
        <br />
        無効化されているユーザのみ削除可能です
      </div>
      <div className="table-responsive">
        <Table hover className="text-center user-table">
          <thead className="small font-weight-normal border-top bg-light text-nowrap">
            <tr>
              <th />
              <th>ID</th>
              <th>氏名</th>
              <th>部門</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link">
                  {sortType === "ASC" ? "▼" : "▲"}
                </Link>
                登録日時
              </th>
              <th>編集</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {users?.length > 0 &&
              users?.map((user: UserData, index: number) => {
                const cognitoUser = usersCognitoData?.find(
                  (cognitoUser: IAccount) => cognitoUser?.Username === user?.user_id
                )
                return (
                  <tr key={user.sub}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">
                      <>
                        {cognitoUser && cognitoUser.UserStatus !== "CONFIRMED" && (
                          <div className="alert alert-danger">パスワード設定エラー</div>
                        )}
                        {cognitoUser && !cognitoUser.Enabled && <span className="text-danger">[無効] </span>}
                      </>
                      <Link to={`/user/${user.sub}/`} className="btn-sm">
                        {cognitoUser?.Username ? (
                          cognitoUser.Username
                        ) : (
                          <Spinner animation="border" size="sm" role="status" variant="primary">
                            <span className="visually-hidden">読み込み中</span>
                          </Spinner>
                        )}
                      </Link>
                    </td>
                    <td className="align-middle">{user.name}</td>
                    <td className="align-middle">
                      {departments?.find((department: IDepartment) => department.id === user.department)?.name}
                    </td>
                    <td className="align-middle">
                      {cognitoUser?.UserCreateDate ? (
                        format(cognitoUser.UserCreateDate, "yyyy-MM-dd HH:mm:ss")
                      ) : (
                        <Spinner animation="border" size="sm" role="status" variant="primary">
                          <span className="visually-hidden">読み込み中</span>
                        </Spinner>
                      )}
                    </td>
                    <td className="text-center align-middle">
                      <Link
                        to="/user/edit/"
                        state={{
                          sub: user.sub,
                          userName: user.name,
                          userDepartment: user.department,
                        }}
                        className="btn btn-sm btn-outline-primary mx-2"
                      >
                        編集
                      </Link>
                    </td>
                    <td className="text-center align-middle">
                      {cognitoUser === undefined ? (
                        <Spinner animation="border" size="sm" role="status" variant="primary">
                          <span className="visually-hidden">読み込み中</span>
                        </Spinner>
                      ) : (
                        <>
                          {cognitoUser?.Enabled && (
                            <Button
                              variant="outline-danger"
                              onClick={() => handleDesable(cognitoUser.Username || "")}
                              className="btn-sm btn-block mx-2"
                            >
                              無効化する
                            </Button>
                          )}
                          {!cognitoUser?.Enabled && (
                            <Button
                              variant="outline-primary"
                              onClick={() => handleEnable(cognitoUser?.Username || "")}
                              className="btn-sm btn-block mx-2"
                            >
                              有効化する
                            </Button>
                          )}
                          {!cognitoUser?.Enabled && (
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(user.sub, user.user_id)}
                              className="btn-sm btn-block mx-2"
                            >
                              削除
                            </Button>
                          )}
                        </>
                      )}
                    </td>
                    {/* <td className="text-center align-middle">
                      <Button
                        variant={user.notification_flag ? "primary" : "danger"}
                        className={`btn-sm btn-block mx-2 ${
                          user.notification_flag ? "border-primary text-white" : "border-danger text-white"
                        }`}
                        disabled
                      >
                        {user.notification_flag ? "有効" : "無効"}
                      </Button>
                    </td> */}
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {pagination.currentPage < pagination.totalPages &&
          (loading ? (
            <Button variant="primary" disabled>
              <Spinner animation="border" size="sm" role="status" variant="light">
                <span className="visually-hidden">読み込み中</span>
              </Spinner>
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => handleFetchUsers(searchText, pagination.currentPage + 1, sortType, false)}
              className="btn-sm btn-block mx-2"
            >
              もっと見る
            </Button>
          ))}
      </div>
    </>
  )
}

export default App
