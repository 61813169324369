import * as apigw from "./apigw"
import { IDepartment, IQuestion, IReportSearch, IReport, SortOrder } from "@/lib/interface"
import axios from "axios"

export const setPasswordDb = async (id: string, password: string, sub: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/set-password", { id, password, sub }, opts)
  return res
}

export const getPasswordDb = async (sub: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-password", { sub }, opts)
  return res
}

export const delPasswordDb = async (sub: string, id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-password", { sub, id }, opts)
  return res
}

export const setDepartmentDb = async (department: IDepartment, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/set-department", { department }, opts)
  return res
}

export const getDepartmentDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-department", { id }, opts)
  return res
}

export const updateDepartmentDb = async (department: IDepartment, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/update-department", { department }, opts)
  return res
}

export const getDepartmentsDb = async (
  searchText?: string,
  type?: string,
  order?: string,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/get-departments", { searchText, type, order }, opts)
  return res
}

export const getAllDepartmentsDb = async (
  searchText?: string,
  type?: string,
  order?: string,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/get-all-departments", { searchText, type, order }, opts)
  return res
}

export const delDepartmentDb = async (id: string, opts?: any) => {
  const res = await apigw.request("/admin/del-department", { id }, opts)
  return res
}

export const getInspectionsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-inspections", opts)
  return res
}

export const setQuestionDb = async (question: IQuestion, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/set-question", { question }, opts)
  return res
}

export const getQuestionDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-question", { id }, opts)
  return res
}

export const updateQuestionDb = async (question: IQuestion, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/update-question", { question }, opts)
  return res
}

export const getQuestionsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-questions", opts)
  return res
}

export const delQuestionDb = async (id: string, opts?: any) => {
  const res = await apigw.request("/admin/del-question", { id }, opts)
  return res
}

export const delReportDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-report", { id }, opts)
  return res
}

export const getWeathersDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-weathers", opts)
  return res
}

export const getContractsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-contracts", opts)
  return res
}

export const getConstructionsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-constructions", opts)
  return res
}

export const getConstructionDetailsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-construction-details", opts)
  return res
}

export const getOperatorsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-operators", opts)
  return res
}

export const getTotalResultDb = async (reportSearch: IReportSearch, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-total-result", { reportSearch }, opts)
  return res
}

export const getReportsDb = async (
  type: string,
  order: string,
  reportSearch: IReportSearch,
  offset?: number,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/get-reports", { type, order, reportSearch, offset }, opts)
  return res
}

export const getReportDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-report", { id }, opts)
  return res
}

export const setReportDb = async (report: IReport, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/set-report", report, opts)
  return res
}

export const updateReportDb = async (report: IReport, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/update-report", report, opts)
  return res
}

export const getImageUploadUrl = async (type: string, ext: string) => {
  const res = await apigw.request<any>("/user/get-image-upload-url", { type, ext })
  return res
}
export const putFile = async (url: string, file: any, opts?: any) => {
  return axios({
    method: "PUT",
    url: url,
    headers: {
      "Content-Type": file.type,
    },
    data: file,
    ...opts,
  })
}

export const getUserDataDb = async (searchText: string, page: number, sortOrder: SortOrder): Promise<any> => {
  const res = await apigw.request("/admin/get-user-data", { searchText, page, sortOrder })
  return res
}

export const getUserDataDbById = async (sub: string): Promise<any> => {
  const res = await apigw.request("/admin/get-user-by-id", { sub })
  return res
}

export const setUserDataDb = async (
  sub: string,
  name: string,
  department: string,
  notificationFlag: boolean,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/set-user-data", { sub, name, department, notificationFlag }, opts)
  return res
}

export const getAdminDataDb = async (subs: string[], opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-admin-data", { subs }, opts)
  return res
}

export const getAdminDataDbById = async (sub: string[]): Promise<any> => {
  const res = await apigw.request("/admin/get-admin-by-id", { sub })
  return res
}

export const setAdminDataDb = async (
  sub: string,
  name: string,
  department: string,
  notificationFlag: boolean,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/set-admin-data", { sub, name, department, notificationFlag }, opts)
  return res
}
