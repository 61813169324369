import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Table from "react-bootstrap/Table"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { format } from "date-fns"
import { Admin } from "@/admin"
import {
  IAccount,
  IAccountAttributes,
  ISortType,
  IDepartment,
  isIDepartmentArray,
  AdminData,
  isAdminDataArray,
} from "@/lib/interface"
import DeleteModal from "./_delete_modal"
import * as api from "@/lib/api"

function App() {
  const [allAdmins, setAllAdmins] = useState<IAccount[]>([])
  const [admins, setAdmins] = useState<IAccount[]>()
  const [adminsData, setAdminsData] = useState<any>()
  const [departments, setDepartments] = useState<IDepartment[]>()
  const [pagenationToken, setPagenationToken] = useState<string>("")
  const [admin, setAdmin] = useState<Admin>()
  const [searchText, setSearchText] = useState("")
  const [attempting] = useState(false)
  const [sortType, setSortType] = useState<ISortType>()
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [delAdmin, setDelAdmin] = useState("")

  useEffect(() => {
    new Admin(setAdmin)
  }, [])

  useEffect(() => {
    handleListAdmins()
  }, [admin])

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), [])

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    if (sortType?.order === "asc") {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    }
  }

  useEffect(() => {
    if (allAdmins?.length > 0 && sortType) {
      let updateAdmins: IAccount[] = []
      if (sortType?.type === "email") {
        updateAdmins = allAdmins?.sort((first: IAccount, second: IAccount) => {
          const firstEmail = first.Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value || ""
          const secondEmail = second.Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value || ""
          if (sortType?.order === "asc") {
            return firstEmail < secondEmail ? -1 : 1
          } else {
            return firstEmail > secondEmail ? -1 : 1
          }
        })
      }
      if (sortType?.type === "date") {
        updateAdmins = allAdmins?.sort((first: IAccount, second: IAccount) => {
          const firstUserCreateDate = new Date(first?.UserCreateDate || "")
          const secondUserCreateDate = new Date(second?.UserCreateDate || "")
          if (sortType?.order === "asc") {
            return firstUserCreateDate < secondUserCreateDate ? -1 : 1
          } else {
            return firstUserCreateDate > secondUserCreateDate ? -1 : 1
          }
        })
      }
      if (sortType?.type === "status") {
        updateAdmins = allAdmins?.sort((first: IAccount, second: IAccount) => {
          const firstEnabled = first.Enabled || false
          const secondEnabled = second.Enabled || false
          if (sortType?.order === "asc") {
            return firstEnabled < secondEnabled ? -1 : 1
          } else {
            return firstEnabled > secondEnabled ? -1 : 1
          }
        })
      }
      setAllAdmins(updateAdmins)
    }
  }, [sortType])

  const handleSetAdmins = () => {
    if (searchText) {
      const admins = allAdmins?.filter((admin: IAccount) => {
        const adminEmail = admin.Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value || ""
        return adminEmail.includes(searchText)
      })
      setAdmins(admins)
    } else {
      setAdmins(allAdmins)
    }
  }

  const handleNotificationToggle = async (sub: string) => {
    try {
      // 現在のフラグ値を取得
      const currentFlag = adminsData?.find((admin) => admin.sub === sub)?.notification_flag

      // 新しいフラグ値を計算
      const newFlag = !currentFlag

      // 必要な情報を取得
      const targetAdmin = adminsData?.find((admin) => admin.sub === sub)
      if (!targetAdmin) {
        throw new Error("対象の管理者が見つかりません")
      }

      // 既存の setAdminDataDb 関数を使用して更新
      await api.setAdminDataDb(sub, targetAdmin.name, targetAdmin.department, newFlag)

      // クライアント側の状態を更新
      setAdminsData((prev) =>
        prev.map((admin) => (admin.sub === sub ? { ...admin, notification_flag: newFlag } : admin))
      )
    } catch (err) {
      console.error("通知フラグの更新中にエラーが発生しました:", err)
    }
  }

  const handleListAdmins = async (pagenationToken?: string) => {
    if (admin) {
      const params = {
        PaginationToken: pagenationToken,
        Limit: 60,
      }
      try {
        const json = await admin.listAdmins(params)
        const adminSubList = json.Users?.filter((user: IAccount) => {
          const userSub = user.Attributes?.find((v: IAccountAttributes) => v.Name === "sub")
          return userSub?.Value !== "admin"
        }).map((user: IAccount) => {
          // フィルタリングされたユーザーから "sub" 属性の Value を取得
          return user.Attributes?.find((attr: IAccountAttributes) => attr.Name === "sub")?.Value
        })
        const fetchAdminData = await api.getAdminDataDb(adminSubList)
        if (isAdminDataArray(fetchAdminData.data.results)) {
          if (adminsData) {
            setAdminsData([...adminsData, ...fetchAdminData.data.results])
          } else {
            setAdminsData(fetchAdminData.data.results)
          }
        }
        const fetchDepartment = await api.getDepartmentsDb()
        if (isIDepartmentArray(fetchDepartment.data.json)) {
          setDepartments(fetchDepartment.data.json)
        }
        if (json) {
          if (allAdmins) {
            setAllAdmins([...allAdmins, ...json.Users])
            setPagenationToken(json.PaginationToken)
          } else {
            setAllAdmins(json)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    if (allAdmins) {
      handleSetAdmins()
    }
  }, [allAdmins])

  const handleEnable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      const json = await admin?.enableAdmin(params)
      if (json) {
        const updateAdmins = allAdmins?.map((admin: IAccount) => {
          if (admin.Username === username) {
            admin.Enabled = true
          }
          return admin
        })
        setAllAdmins(updateAdmins)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDesable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      const json = admin?.disableAdmin(params)
      if (json) {
        const updateAdmins = allAdmins?.map((admin: IAccount) => {
          if (admin.Username === username) {
            admin.Enabled = false
          }
          return admin
        })
        setAllAdmins(updateAdmins)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async (username: string) => {
    setDeleteModalShow(true)
    setDelAdmin(username)
  }

  return (
    <>
      <DeleteModal
        admin={admin}
        delAdmin={delAdmin}
        allAdmins={allAdmins}
        setAllAdmins={setAllAdmins}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <div className="row">
        <h1 className="h4 col-12 col-md-5">管理者一覧（{admins && admins?.length > 0 ? admins.length : "-"} 件）</h1>
        <div className="col-md-4"></div>
        <div className="col-12 col-md-3">
          <Link to={`/admin/create/`} className="btn btn-orange fw-bold float-end w-100">
            新しく管理者を追加する
          </Link>
        </div>
      </div>
      <hr />

      <Form className="search-area mb-3">
        <div className="row mb-4">
          <div className="col-md-4 col-12">
            <Form.Group>
              <Form.Control
                placeholder="admin@ptchecksheet.com"
                type="email"
                disabled={attempting}
                onChange={handleEmailChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-4 col-12 mt-2 mt-md-0">
            <Button disabled={attempting} onClick={handleSetAdmins} className="search-btn btn-block">
              検索
            </Button>
          </div>
        </div>
      </Form>
      <div className="alert alert-warning">
        無効化されている管理者はログインできません。
        <br />
        無効化されている管理者のみ削除可能です
      </div>
      <div className="table-responsive">
        <Table hover className="text-center admin-table">
          <thead className="small font-weight-normal border-top bg-light">
            <tr>
              <th></th>
              <th>メールアドレス</th>
              <th>氏名</th>
              <th>部門</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="date">
                  {sortType?.type === "date" && sortType?.order === "asc" ? "▼" : "▲"}
                </Link>
                登録日時
              </th>
              <th>編集</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="status">
                  {sortType?.type === "status" && sortType?.order === "asc" ? "▼" : "▲"}
                </Link>
                操作
              </th>
              <th>メール送信</th>
            </tr>
          </thead>
          <tbody>
            {admins &&
              admins?.length > 0 &&
              admins?.map((admin: IAccount, index: number) => {
                return (
                  <tr key={admin.Username}>
                    <td className="text-center align-middle">{index + 1}</td>
                    <td className="text-center align-middle">
                      {!admin.Enabled && <span className="text-danger">[無効] </span>}
                      {admin.Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value}
                    </td>
                    <td className="align-middle">
                      {
                        adminsData?.find(
                          (adminData: AdminData) =>
                            adminData.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                        )?.name
                      }
                    </td>
                    <td className="align-middle">
                      {
                        departments?.find(
                          (department: IDepartment) =>
                            department.id ===
                            adminsData?.find(
                              (adminData: AdminData) =>
                                adminData.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                            )?.department
                        )?.name
                      }
                    </td>
                    <td className="text-center align-middle">
                      {format(new Date(admin.UserCreateDate || ""), "yyyy-MM-dd HH:mm:ss")}
                    </td>
                    <td className="text-center align-middle">
                      <Link
                        to={`/admin/edit/`}
                        className="btn btn-sm btn-outline-primary mx-2"
                        state={{
                          sub: admin.Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value,
                          adminName: adminsData?.find(
                            (adminData: AdminData) =>
                              adminData.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                          )?.name,
                          adminDepartment: adminsData?.find(
                            (adminData: AdminData) =>
                              adminData.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                          )?.department,
                          adminEmail: admin.Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value,
                        }}
                      >
                        編集
                      </Link>
                    </td>
                    <td className="text-center align-middle">
                      {admin.Enabled && (
                        <Button
                          variant="outline-danger"
                          onClick={() => handleDesable(admin.Username || "")}
                          className="btn-sm btn-block mx-2"
                        >
                          無効化する
                        </Button>
                      )}
                      {!admin.Enabled && (
                        <Button
                          variant="outline-primary"
                          onClick={() => handleEnable(admin.Username || "")}
                          className="btn-sm btn-block mx-2"
                        >
                          有効化する
                        </Button>
                      )}
                      {!admin.Enabled && (
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(admin.Username || "")}
                          className="btn-sm btn-block mx-2"
                        >
                          削除
                        </Button>
                      )}
                    </td>
                    <td className="text-center align-middle">
                      <Form.Group
                        className={`d-inline-block p-2 border ${
                          adminsData?.find(
                            (data) => data.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                          )?.notification_flag
                            ? "border-primary text-primary"
                            : "border-danger text-danger"
                        }`}
                        style={{ borderRadius: "4px" }}
                      >
                        <Form.Check
                          type="switch"
                          id={`notification-flag-${admin.Attributes?.find((attr) => attr.Name === "sub")?.Value}`}
                          label={
                            adminsData?.find(
                              (data) => data.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                            )?.notification_flag
                              ? "有効"
                              : "無効"
                          }
                          checked={
                            adminsData?.find(
                              (data) => data.sub === admin.Attributes?.find((attr) => attr.Name === "sub")?.Value
                            )?.notification_flag || false
                          }
                          onChange={() =>
                            handleNotificationToggle(admin.Attributes?.find((attr) => attr.Name === "sub")?.Value)
                          }
                          className="ms-2"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {pagenationToken && (
          <Button variant="primary" onClick={() => handleListAdmins(pagenationToken)} className="btn-sm btn-block mx-2">
            もっと見る
          </Button>
        )}
      </div>
    </>
  )
}

export default App
