// import { StringInput } from "aws-sdk/clients/inspector2"

import { bool } from "aws-sdk/clients/signer"

export interface IAccount {
  Attributes?: IAccountAttributes[]
  Enabled?: boolean
  UserCreateDate?: Date
  UserLastModifiedDate?: Date
  UserStatus?: string
  Username?: string
  notification_flag?: boolean
}

export interface IAccountAttributes {
  Name?: string
  Value?: string
}

export interface IDepartment {
  id?: string
  view_number?: number
  name?: string
  status?: number
  report_count?: number
  question_count?: number
  create_date?: string
  update_date?: string
}
export function isIDepartment(obj: unknown): obj is IDepartment {
  if (typeof obj === "object" && obj !== null) {
    const dept = obj as IDepartment
    return (
      (typeof dept.id === "string" || typeof dept.id === "undefined") &&
      (typeof dept.view_number === "number" || typeof dept.view_number === "undefined") &&
      (typeof dept.name === "string" || typeof dept.name === "undefined") &&
      (typeof dept.status === "number" || typeof dept.status === "undefined") &&
      (typeof dept.report_count === "number" || typeof dept.report_count === "undefined") &&
      (typeof dept.question_count === "number" || typeof dept.question_count === "undefined") &&
      (typeof dept.create_date === "string" || typeof dept.create_date === "undefined") &&
      (typeof dept.update_date === "string" || typeof dept.update_date === "undefined")
    )
  }
  return false
}

export function isIDepartmentArray(obj: unknown): obj is IDepartment[] {
  if (Array.isArray(obj)) {
    let isValid = true

    obj.forEach((item, index) => {
      if (!isIDepartment(item)) {
        console.log(`要素 ${index} は IDepartment 型の条件に合いません:`, item)
        isValid = false
      }
    })

    return isValid
  }

  console.log("与えられたオブジェクトは配列ではありません:", obj)
  return false
}

export interface ISortType {
  type?: string
  order?: string
}

export interface IReportComments {
  author: string
  comment: string
}

export function isValidReportComments(object: unknown): object is IReportComments {
  if (typeof object !== "object" || object === null || Array.isArray(object)) {
    return false
  }

  const report = object as Record<string, unknown>

  return (
    "author" in report &&
    typeof report["author"] === "string" &&
    "comment" in report &&
    typeof report["comment"] === "string"
  )
}

export function isArrayReportComments(object: unknown): object is IReportComments[] {
  if (!Array.isArray(object)) {
    return false
  }

  return object.every((item) => isValidReportComments(item))
}

export type OldImages = {
  questionId: string
  imagePath: string
}

export type SortOrder = "ASC" | "DESC"

export interface IInspection {
  id?: string
  view_number?: number
  name?: string
  create_date?: string
  update_date?: string
}

export interface IQuestion {
  id?: string
  view_number?: number
  class_name?: string
  name?: string
  inspection_id?: string
  inspection_name?: string
  department_id?: string
  department_name?: string
  status?: number
  report_count?: number
  create_date?: string
  update_date?: string
  RESULT_SLASH?: number
  RESULT_CIRCLE?: number
  RESULT_TRIANGLE?: number
  RESULT_CROSS?: number
}

export interface IReportSearch {
  job_category?: string
  job_number?: string
  job_code1?: string
  job_code2?: string
  job_date_start?: string
  job_date_end?: string
  weather_id?: string[]
  construction?: string
  contract_id?: string[]
  contract_amount_start?: string
  contract_amount_end?: string
  department_id?: string[]
  office_name?: string
  construction_id?: string[]
  construction_detail_id?: string[]
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: string[]
  construction_company?: string
  partners?: string
  comment?: string | IReportComments[]
  request_comment?: string | IReportComments[]
  inspections?: string[]
}

export interface IOption {
  id?: string
  view_number?: number
  class_name?: string
  name?: string
  disabled?: boolean
}

export interface IReport {
  id?: string
  department_name?: string
  job_category?: string
  job_number?: string
  job_date?: string
  weather_id?: string
  temperature?: string
  construction?: string
  contract_id?: string
  contract_amount?: string
  construction_period_start?: string
  construction_period_end?: string
  progress_rate?: string
  department_id?: string
  office_name?: string
  construction_id?: string
  construction_detail_id?: string
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: IOperator[]
  construction_company?: string
  partners?: string[]
  comment?: string
  request_comment?: string
  inspections?: string[]
  questions?: IReportQuestion[]
  status?: string
  create_date?: string
  update_date?: string
  user_id?: string
  user_name?: string
  errors?: IReportError
}

export interface IReportError {
  id?: string
  job_category?: string
  job_number?: string
  job_date?: string
  weather_id?: string
  temperature?: string
  construction?: string
  contract_id?: string
  contract_amount?: string
  construction_period_start?: string
  construction_period_end?: string
  progress_rate?: string
  department_id?: string
  office_name?: string
  construction_id?: string
  construction_detail_id?: string
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: IOperatorError[]
  construction_company?: string
  partners?: string[]
  comment?: string
  request_comment?: string
  inspections?: string
  questions?: IQuestionError[]
}

export interface IQuestionError {
  question_id?: string
  comment?: string
  image?: string
}

export interface IOperator {
  operator_id?: string
  operator_number?: number | null
  operator_other?: string | IOperatorOther[] | null
}

function isOperator(obj: unknown): obj is IOperator {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const operator = obj as Record<string, unknown>

  const stringProps = ["operator_id"]
  for (const prop of stringProps) {
    if (prop in operator && typeof operator[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  const numberProps = ["operator_number"]
  for (const prop of numberProps) {
    if (prop in operator && operator[prop] !== null && typeof operator[prop] !== "number") {
      console.error(`Property '${prop}' is not a number or null`)
      return false
    }
  }

  const otherProps = ["operator_other"]
  for (const prop of otherProps) {
    if (prop in operator && !isArrayORStringReportOperatorOther(operator[prop])) {
      console.error(`Property '${prop}' is not an array of IOperatorOther`)
      return false
    }
  }

  return true
}

export function isArrayOperators(obj: unknown): obj is IOperator[] {
  if (!Array.isArray(obj)) {
    console.error("Object is not an array in isArrayOperators")
    return false
  }
  return obj.every((item) => isOperator(item))
}

export interface IOperatorOther {
  other_name: string
  other_number: number | null
}

export function isOperatorOther(obj: unknown): obj is IOperatorOther {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const operator = obj as Record<string, unknown>

  const stringProps = ["other_name"]
  for (const prop of stringProps) {
    if (prop in operator && typeof operator[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  const numberProps = ["other_number"]
  for (const prop of numberProps) {
    if (prop in operator && operator[prop] !== null && typeof operator[prop] !== "number") {
      console.error(`Property '${prop}' is not a number or null`)
      return false
    }
  }

  return true
}

export function isArrayORStringReportOperatorOther(obj: unknown): obj is IOperatorOther[] {
  if (typeof obj === "string") {
    return true
  }
  if (!Array.isArray(obj)) {
    console.error("Object is not an array in isArrayORStringReportOperatorOther")
    return false
  }

  return obj.every((item) => isOperatorOther(item))
}

export interface IReportQuestion {
  inspection_id?: string
  department_id?: string
  id?: string
  result_id?: string
  view_number?: number
  class_name?: string
  name?: string
  comment?: string
  status?: number
  create_date?: string
  update_date?: string
}

export interface IOption {
  id?: string
  viewNumber?: number
  class_name?: string
  name?: string
  // disabledはoperatorのみに使用
  disabled?: boolean
  // job_numberはdepartmentのみに使用
  job_number?: string
}

export interface IOperatorError {
  idMessage?: string
  numMessage?: string
}

export interface IReportQuestion {
  inspection_id?: string
  inspection_name?: string
  department_id?: string
  department_name?: string
  id?: string
  result_id?: string
  view_number?: number
  class_name?: string
  name?: string
  comment?: string
  image?: string
  //image_fileはアップロードの際に一時的に保持するデータ DBにはない
  image_file?: File | null
  status?: number
  create_date?: string
  update_date?: string
}

export interface Pagination {
  currentPage: number
  total: number
  totalPages: number
  perPage: number
}

export interface UserData {
  sub: string
  user_id: string
  name: string
  department: string
  notification_flag: boolean
}

export function isUserData(obj: unknown): obj is UserData {
  if (typeof obj === "object" && obj !== null) {
    const user = obj as UserData
    return (
      typeof user.sub === "string" &&
      (typeof user.name === "string" || user.name === null) &&
      (typeof user.department === "string" || user.department === null)
    )
  }
  return false
}

export function isUserDataArray(obj: unknown): obj is UserData[] {
  if (Array.isArray(obj)) {
    let isValid = true

    obj.forEach((item, index) => {
      if (!isUserData(item)) {
        console.log(`要素 ${index} は UserData 型の条件に合いません:`, item)
        isValid = false
      }
    })

    return isValid
  }

  console.log("与えられたオブジェクトは配列ではありません:", obj)
  return false
}

export interface AdminData {
  sub: string
  name: string
  department?: string
  notification_flag?: boolean
}

export function isAdminData(obj: unknown): obj is AdminData {
  if (typeof obj === "object" && obj !== null) {
    const user = obj as UserData
    return typeof user.sub === "string" && typeof user.name === "string" && typeof user.department === "string"
  }
  return false
}

export function isAdminDataArray(obj: unknown): obj is AdminData[] {
  if (Array.isArray(obj)) {
    let isValid = true

    obj.forEach((item, index) => {
      if (!isAdminData(item)) {
        console.log(`要素 ${index} は AdminData 型の条件に合いません:`, item)
        isValid = false
      }
    })

    return isValid
  }

  console.log("与えられたオブジェクトは配列ではありません:", obj)
  return false
}

export interface IReportComments {
  author: string
  comment: string
}

export interface IOperatorOther {
  other_name: string
  other_number: number | null
}
